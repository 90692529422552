export default {
  fonts: {
    body: "Inconsolata", // Iosevka
    monospace: "Inconsolata",
  },
  colors: {
    text: "black",
    background: "white",
    primary: "blue",
  },
};
