
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import quicktheme from './theme'
import { Head,themes } from 'mdx-deck'
import { CodeSurfer } from "code-surfer"
import "prismjs/components/prism-rust"
import { github } from "@code-surfer/themes"
import ErrorLayout from './ErrorLayout.js'
import './styles.css'
export const theme = { ...quicktheme,
  ...themes.prism,
  ...quicktheme
};

const layoutProps = {
  theme
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Head mdxType="Head">
  <title>Intro to errors in Rust</title>
    </Head>
    <h1>{`Hey`}</h1>
    <p>{`A quick intro to error handling in Rust`}</p>
    <hr></hr>
    <h1>{`It's just an enum! 🤷`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`Result<Value, Error>
`}</code></pre>
    <hr></hr>
    <p>{`Let's see some code`}</p>
    <hr></hr>
    <CodeSurfer theme={github} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`enum FileKind {
    Rust,
    NotRust,
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`enum FileKind {
    Rust(String),
    NotRust(String),
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`enum FileKind {
    Rust(String),
    NotRust(String),
}

fn is_rustfile(name: &str) -> FileKind {
    if name.ends_with(".rs") {
        FileKind::Rust(name.to_string())
    } else {
        FileKind::NotRust(name.to_string())
    }
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`enum FileKind {
    Rust(String),
    NotRust(String),
}

fn is_rustfile(name: &str) -> FileKind {
    if name.ends_with(".rs") {
        FileKind::Rust(name.to_string())
    } else {
        FileKind::NotRust(name.to_string())
    }
}

fn main() {
    println!("main.rs: {:?}", is_rustfile("main.rs"));
    println!("main.go: {:?}", is_rustfile("main.go"));
}

// main.rs: Rust("main.rs")
// main.go: NotRust("main.go")
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`enum FileKind {
    Rust(String),
    NotRust(String),
}

fn is_rustfile(name: &str) -> FileKind {
    if name.ends_with(".rs") {
        FileKind::Rust(name.to_string())
    } else {
        FileKind::NotRust(name.to_string())
    }
}

fn main() {
    println!("main.rs: {:?}", is_rustfile("main.rs"));
    println!("main.go: {:?}", is_rustfile("main.go"));
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`enum FileKind {
    Rust(String),
    None
}

fn is_rustfile(name: &str) -> FileKind {
    if name.ends_with(".rs") {
        FileKind::Rust(name.to_string())
    } else {
        FileKind::None
    }
}

fn main() {
    println!("main.rs: {:?}", is_rustfile("main.rs"));
    println!("main.go: {:?}", is_rustfile("main.go"));
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`enum FileKind {
    Rust(String),
    None
}

fn is_rustfile(name: &str) -> FileKind {
    if name.ends_with(".rs") {
        FileKind::Rust(name.to_string())
    } else {
        FileKind::None
    }
}

fn main() {
    println!("main.rs: {:?}", is_rustfile("main.rs"));
    println!("main.go: {:?}", is_rustfile("main.go"));
}

// main.rs: Rust("main.rs")
// main.go: None
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`enum FileKind {
    Rust(String),
    None
}

fn is_rustfile(name: &str) -> FileKind {
    if name.ends_with(".rs") {
        FileKind::Rust(name.to_string())
    } else {
        FileKind::None
    }
}

fn main() {
    println!("main.rs: {:?}", is_rustfile("main.rs"));
    println!("main.go: {:?}", is_rustfile("main.go"));
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`fn is_rustfile(name: &str) -> Option<String> {
    if name.ends_with(".rs") {
        Some(name.to_string())
    } else {
        None
    }
}

fn main() {
    println!("main.rs: {:?}", is_rustfile("main.rs"));
    println!("main.go: {:?}", is_rustfile("main.go"));
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`fn is_rustfile(name: &str) -> Option<String> {
    if name.ends_with(".rs") {
        Some(name.to_string())
    } else {
        None
    }
}

fn main() {
    println!("main.rs: {:?}", is_rustfile("main.rs"));
    println!("main.go: {:?}", is_rustfile("main.go"));
}

// main.rs: Some("main.rs")
// main.go: None
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <p>{`...`}</p>
    <hr></hr>
    <CodeSurfer theme={github} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`enum Option<ValueType> {
    Some(Valuetype),
    None
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`enum Option<String> {
    Some(String),
    None
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`enum Result<ValueType, ErrorType> {
    Ok(ValueType),
    Err(ErrorType)
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`enum Result<String, String> {
    Ok(String),
    Err(String)
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <p>{`...`}</p>
    <hr></hr>
    <CodeSurfer theme={github} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`fn is_rustfile(name: &str) -> Option<String> {
    if name.ends_with(".rs") {
        Some(name.to_string())
    } else {
        None
    }
}

fn main() {
    println!("main.rs: {:?}", is_rustfile("main.rs"));
    println!("main.go: {:?}", is_rustfile("main.go"));
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`fn is_rustfile(name: &str) -> Result<String> {
    if name.ends_with(".rs") {
        Ok(name.to_string())
    } else {
        Err(String::from("Where is my rust file?"))
    }
}

fn main() {
    println!("main.rs: {:?}", is_rustfile("main.rs"));
    println!("main.go: {:?}", is_rustfile("main.go"));
}

// main.rs: Ok("main.rs")
// main.go: Err("Where is my rust file?")
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <p>{`So yeah, just an enum.`}</p>
    <hr></hr>
    <h1>{`Handling errors`}</h1>
    <hr></hr>
    <CodeSurfer theme={github} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`use std::fs::File;

fn main() {
    let mut file = File::open("secret-to-understanding-borrow-checker.txt");
    let mut contents = String::new();
    file.read_to_string(&mut contents);
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <ErrorLayout mdxType="ErrorLayout">
      <pre><code parentName="pre" {...{}}>{`error[E0599]: no method named \`read_to_string\` found for enum \`std::result::Result<std::fs::File, std::io::Error>\` in the current scope
 --> src/readfile.rs:6:10
  |
6 |     file.read_to_string(&mut contents);
  |          ^^^^^^^^^^^^^^ method not found in \`std::result::Result<std::fs::File, std::io::Error>\`

error: aborting due to previous error

For more information about this error, try \`rustc --explain E0599\`.
`}</code></pre>
    </ErrorLayout>
    <hr></hr>
    <CodeSurfer theme={github} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`use std::fs::File;

fn main() {
    let mut file = File::open("secret-to-understanding-borrow-checker.txt");
    let mut contents = String::new();
    file.read_to_string(&mut contents);
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`use std::fs::File;

fn main() {
    let mut file = File::open("secret-to-understanding-borrow-checker.txt").unwrap();
    let mut contents = String::new();
    file.read_to_string(&mut contents);
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`It compiles!`}</h1>
    <hr></hr>
    <h1>{`Woops! | panic!()`}</h1>
    <pre><code parentName="pre" {...{}}>{`thread 'main' panicked at 'called \`Result::unwrap()\` on an \`Err\` value: Os { code: 2, kind: NotFound,
message: "No such file or directory" }', src/readfile.rs:5:42
note: run with \`RUST_BACKTRACE=1\` environment variable to display a backtrace
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Use `}<inlineCode parentName="p">{`unwrap_or`}</inlineCode>{` if you wanna pass default value`}</p>
    </blockquote>
    <hr></hr>
    <CodeSurfer theme={github} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`use std::fs::File;

fn main() {
    let mut file = File::open("secret-to-understanding-borrow-checker.txt").unwrap();
    let mut contents = String::new();
    file.read_to_string(&mut contents);
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`use std::fs::File;

fn main() {
    let mut file = File::open("secret-to-understanding-borrow-checker.txt");
    match file {
        Ok(mut f) => {
            let mut contents = String::new();
            f.read_to_string(&mut contents);
        }
        Err(_) => {
            println!("Hey, I don't think that file exists.");
        }
    };
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`Hey, I don't think that file exists.
`}</code></pre>
    <hr></hr>
    <h1>{`? operator`}</h1>
    <hr></hr>
    <CodeSurfer theme={github} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`use std::fs::File;
use std::io::prelude::*;

fn main() {
    let mut file = File::open("foo.txt");
    match file {
        Ok(mut f) => {
            let mut contents = String::new();
            f.read_to_string(&mut contents);
        }
        Err(_) => {
            println!("Hey, I don't think that file exists.");
        }
    };
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust",
          "metastring": "5:15",
          "5:15": true
        }}>{`use std::fs::File;
use std::io::prelude::*;
use std::io::Error;

fn read_file_contents(name: &str) -> Result<String, Error> {
    let file = File::open(name);
    match file {
        Ok(mut f) => {
            let mut contents = String::new();
            f.read_to_string(&mut contents);
            Ok(contents)
        }
        Err(err) => Err(err),
    }
}

fn main() {
    let file_contents = read_file_contents("foo.txt");
    match file_contents {
        Ok(fc) => {
            println!("{}", fc);
        }
        Err(_) => {
            println!("Hey, I don't think that file exists.");
        }
    };
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust",
          "metastring": "5:18",
          "5:18": true
        }}>{`use std::fs::File;
use std::io::prelude::*;
use std::io::Error;

fn read_file_contents(name: &str) -> Result<String, Error> {
    let file = File::open(name);
    match file {
        Ok(mut f) => {
            let mut contents = String::new();
            let read_error = f.read_to_string(&mut contents);
            return match read_error {
                Ok(_) => Ok(contents),
                Err(e) => Err(e)
            }
        }
        Err(err) => Err(err),
    }
}

fn main() {
    let file_contents = read_file_contents("foo.txt");
    match file_contents {
        Ok(fc) => {
            println!("{}", fc);
        }
        Err(_) => {
            println!("Hey, I don't think that file exists.");
        }
    };
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`use std::fs::File;
use std::io::prelude::*;
use std::io::Error;

fn read_file_contents(name: &str) -> Result<String, Error> {
    let mut file = File::open(name)?;
    let mut contents = String::new();
    file.read_to_string(&mut contents)?;
    Ok(contents)
}

fn main() {
    let file_contents = read_file_contents("foo.txt");
    match file_contents {
        Ok(fc) => {
            println!("{}", fc);
        }
        Err(_) => {
            println!("Hey, I don't think that file exists.");
        }
    };
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`use std::fs::File;
use std::io::prelude::*;
use std::io::Error;

fn read_file_contents(name: &str) -> Result<String, Error> {
    let mut file = File::open(name)?;
    let mut contents = String::new();
    file.read_to_string(&mut contents)?;
    Ok(contents)
}

fn main() -> std::io::Result<()> {
    let file_contents = read_file_contents("foo.txt")?;
    println!("{}", file_contents);
    Ok(())
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <p>{`...`}</p>
    <hr></hr>
    <CodeSurfer theme={github} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-rust",
          "metastring": "6:17,22:29",
          "6:17,22:29": true
        }}>{`use std::fs::File;
use std::io::prelude::*;
use std::io::Error;

fn read_file_contents(name: &str) -> Result<String, Error> {
    let file = File::open(name);
    match file {
        Ok(mut f) => {
            let mut contents = String::new();
            let read_error = f.read_to_string(&mut contents);
            return match read_error {
                Ok(_) => Ok(contents),
                Err(e) => Err(e)
            }
        }
        Err(err) => Err(err),
    }
}

fn main() {
    let file_contents = read_file_contents("foo.txt");
    match file_contents {
        Ok(fc) => {
            println!("{}", fc);
        }
        Err(_) => {
            println!("Hey, I don't think that file exists.");
        }
    };
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust",
          "metastring": "6:9,13:14",
          "6:9,13:14": true
        }}>{`use std::fs::File;
use std::io::prelude::*;
use std::io::Error;

fn read_file_contents(name: &str) -> Result<String, Error> {
    let mut file = File::open(name)?;
    let mut contents = String::new();
    file.read_to_string(&mut contents)?;
    Ok(contents)
}

fn main() -> std::io::Result<()> {
    let file_contents = read_file_contents("foo.txt")?;
    println!("{}", file_contents);
    Ok(())
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`That is the basics`}</h1>
    <hr></hr>
    <h1>{`Custom error types`}</h1>
    <hr></hr>
    <CodeSurfer theme={github} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`// two little errors, walking down the hill
enum CustomErrors {
    NoFile,
    NonRustFile
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`use std::error::Error;

#[derive(Debug)]
enum CustomErrors {
    NoFile,
    NonRustFile
}

// officially make it an error
impl Error for CustomErrors {}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`Helpful compiler`}</h1>
    <pre><code parentName="pre" {...{}}>{`error[E0277]: \`CustomErrors\` doesn't implement \`std::fmt::Display\`
  --> src/customerrors.rs:9:6
   |
9  | impl Error for CustomErrors {}
   |      ^^^^^ \`CustomErrors\` cannot be formatted with the default formatter
   | 
  ::: rustlib/src/rust/src/libstd/error.rs:48:26
   |
48 | pub trait Error: Debug + Display {
   |                          ------- required by this bound in \`std::error::Error\`
   |
   = help: the trait \`std::fmt::Display\` is not implemented for \`CustomErrors\`
`}</code></pre>
    <hr></hr>
    <CodeSurfer theme={github} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`use std::error::Error;

#[derive(Debug)]
enum CustomErrors {
    NoFile,
    NonRustFile,
}

impl Error for CustomErrors {}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`use std::error::Error;
use std::fmt;

#[derive(Debug)]
enum CustomErrors {
    NoFile,
    NonRustFile,
}

impl Error for CustomErrors {}
impl fmt::Display for CustomErrors {
    fn fmt(&self, f: &mut fmt::Formatter) -> fmt::Result {
        write!(f, "error: {:?}", self)
    }
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`use std::error::Error;
use std::fmt;
use std::fs::File;

#[derive(Debug)]
enum CustomErrors {
    NoFile,
    NonRustFile,
}

impl Error for CustomErrors {}
impl fmt::Display for CustomErrors {
    fn fmt(&self, f: &mut fmt::Formatter) -> fmt::Result {
        write!(f, "error: {:?}", self)
    }
}

fn do_thing() -> Result<(), CustomErrors> {
    let file = File::open("foo.txt")?;
    Ok(())
}

fn main() {
    do_thing();
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <ErrorLayout mdxType="ErrorLayout">
      <pre><code parentName="pre" {...{}}>{`error[E0277]: \`?\` couldn't convert the error to \`CustomErrors\`
  --> src/customerrors2.rs:19:37
   |
18 | fn do_thing() -> Result<(), CustomErrors> {
   |                  ------------------------ expected \`CustomErrors\` because of this
19 |     let file = File::open("foo.txt")?;
   |                                     ^ the trait \`std::convert::From<std::io::Error>\` is not implemented for \`CustomErrors\`
   |
   = note: the question mark operation (\`?\`) implicitly performs a conversion on the error value using the \`From\` trait
   = note: required by \`std::convert::From::from\`

error: aborting due to previous error

For more information about this error, try \`rustc --explain E0277\`.
`}</code></pre>
    </ErrorLayout>
    <hr></hr>
    <CodeSurfer theme={github} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`use std::error::Error;
use std::fmt;
use std::fs::File;

#[derive(Debug)]
enum CustomErrors {
    NoFile,
    NonRustFile,
}

impl Error for CustomErrors {}
impl fmt::Display for CustomErrors {
    fn fmt(&self, f: &mut fmt::Formatter) -> fmt::Result {
        write!(f, "error: {:?}", self)
    }
}

fn do_thing() -> Result<(), CustomErrors> {
    let file = File::open("foo.txt")?;
    Ok(())
}

fn main() {
    do_thing();
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`use std::error::Error;
use std::fmt;
use std::fs::File;

#[derive(Debug)]
enum CustomErrors {
    NoFile,
    NonRustFile,
}

impl Error for CustomErrors {}
impl fmt::Display for CustomErrors {
    fn fmt(&self, f: &mut fmt::Formatter) -> fmt::Result {
        write!(f, "error: {:?}", self)
    }
}

fn do_thing() -> Result<(), Box<dyn Error>> {
    let file = File::open("foo.txt")?;
    Ok(())
}

fn main() {
    do_thing();
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`But there is a better way`}</h1>
    <p>{`Convert all errors to our custom type`}</p>
    <hr></hr>
    <CodeSurfer theme={github} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`use std::error::Error;
use std::fmt;
use std::fs::File;

#[derive(Debug)]
enum CustomErrors {
    NoFile,
    NonRustFile,
}

impl Error for CustomErrors {}
impl fmt::Display for CustomErrors {
    fn fmt(&self, f: &mut fmt::Formatter) -> fmt::Result {
        write!(f, "error: {:?}", self)
    }
}

fn do_thing() -> Result<(), Box<dyn Error>> {
    let file = File::open("foo.txt")?;
    Ok(())
}

fn main() {
    do_thing();
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`fn do_thing() -> Result<(), Box<dyn Error>> {
    let file = File::open("foo.txt")?;
    Ok(())
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`fn do_thing() -> Result<(), CustomErrors> {
    let file = File::open("foo.txt").map_err(|_| CustomErrors::NoFile)?;
    Ok(())
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`OR`}</h1>
    <hr></hr>
    <CodeSurfer theme={github} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`impl Error for CustomErrors {}
impl fmt::Display for CustomErrors {
    fn fmt(&self, f: &mut fmt::Formatter) -> fmt::Result {
        write!(f, "error: {:?}", self)
    }
}

fn do_thing() -> Result<(), CustomErrors> {
    let file = File::open("foo.txt")?;
    Ok(())
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-rust"
        }}>{`impl Error for CustomErrors {}
impl fmt::Display for CustomErrors {
    fn fmt(&self, f: &mut fmt::Formatter) -> fmt::Result {
        write!(f, "error: {:?}", self)
    }
}
impl From<std::io::Error> for CustomErrors {
    fn from(error: std::io::Error) -> CustomErrors {
        CustomErrors::NoFile
    }
}

fn do_thing() -> Result<(), CustomErrors> {
    let file = File::open("foo.txt")?;  // that is cleaner
    Ok(())
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <p>{`What about all those libraries that I keep hearing about?`}</p>
    <hr></hr>
    <CodeSurfer theme={github} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-rust",
          "metastring": "title=\"thiserror\"",
          "title": "\"thiserror\""
        }}>{`use thiserror::Error;

#[derive(Error, Debug)]
pub enum DataStoreError {
    #[error("data store disconnected")]
    Disconnect(#[from] io::Error),
    #[error("the data for key \`{0}\` is not available")]
    Redaction(String),
    #[error("invalid header (expected {expected:?}, found {found:?})")]
    InvalidHeader {
        expected: String,
        found: String,
    },
    #[error("unknown data store error")]
    Unknown,
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <CodeSurfer theme={github} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-rust",
          "metastring": "title=\"anyhow\"",
          "title": "\"anyhow\""
        }}>{`use anyhow::Result;

fn get_cluster_info() -> Result<ClusterMap> {
    let config = std::fs::read_to_string("cluster.json")?;
    let map: ClusterMap = serde_json::from_str(&config)?;
    Ok(map)
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <p><a parentName="p" {...{
        "href": "https://github.com/dtolnay/thiserror"
      }}>{`dtolnay/thiserror`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/dtolnay/anyhow"
      }}>{`dtolnay/anyhow`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/shepmaster/snafu"
      }}>{`shepmaster/snafu`}</a>{`
`}<a parentName="p" {...{
        "href": "https://github.com/tailhook/quick-error"
      }}>{`quick-error`}</a></p>
    <p>{`...`}</p>
    <p>{`There are quite a few, but you get the idea`}</p>
    <hr></hr>
    <h1>{`Bye! 🙌`}</h1>
    <p>{`Slides `}<a parentName="p" {...{
        "href": "http://rust-errors.talks.meain.io"
      }}>{`rust-errors.talks.meain.io`}</a><br parentName="p"></br>{`
`}{`Code `}<a parentName="p" {...{
        "href": "https://github.com/meain/rust-errors-talk"
      }}>{`github.com/meain/rust-errors-talk`}</a></p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;